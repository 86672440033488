<template>
  <div>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <form enctype="multipart/form-data">
      <div class="tabs-content-wrapper loyality-points">
        <div class="w-100 pb-3">
          <h4 class="card-label border-bottom">
            General Settings
          </h4>
        </div>
        <div class="tabs-inner-content">
          <div class="form-group d-flex align-items-center">
            <label class="mr-3">Halal:</label>
            <b-form-select
              v-model="restaurant.halal_selected"
              :options="halal_options"
              class=""
              value-field="item"
              text-field="name"
            ></b-form-select>
          </div>
          <div class="form-group d-flex align-items-center">
            <label class="mr-3">Delivery:</label>
            <b-form-select
              v-model="restaurant.delivery_selected"
              :options="delivery_options"
              class=""
              value-field="item"
              text-field="name"
            ></b-form-select>
          </div>
          <div class="form-group d-flex align-items-center">
            <label class="mr-3">Delivery Within:</label>
            <input
              v-model="restaurant.delivery_within"
              type="text"
              class="form-control"
              placeholder="15 miles"
            />
          </div>
          <div class="form-group d-flex align-items-center">
            <label class="mr-3">Delivery Fee:</label>
            <input
              v-model="restaurant.delivery_fee"
              type="text"
              class="form-control"
              placeholder="3"
            />
          </div>
          <div class="form-group d-flex align-items-center">
            <label class="mr-3">Minimum Order:</label>
            <input
              v-model="restaurant.minimum_order"
              type="text"
              class="form-control"
              placeholder="5"
            />
          </div>
        </div>
        <div class="tabs-inner-content">
          <div class="form-group d-flex align-items-center">
            <label class="mr-3">Delivery Starting:</label>
            <input
              v-model="restaurant.delivery_starting"
              type="time"
              class="form-control"
            />
          </div>
          <div class="form-group d-flex align-items-center">
            <label class="mr-3">Delivery Time (Minute):</label>
            <input
              v-model="restaurant.delivery_time"
              type="text"
              class="form-control"
              placeholder="00:00:00"
            />
          </div>
          <div class="form-group d-flex align-items-center">
            <label class="mr-3">Collection Time (Minute):</label>
            <input
              v-model="restaurant.collection_time"
              type="text"
              class="form-control"
              placeholder="00:00:00"
            />
          </div>
          <div class="form-group d-flex align-items-center">
            <label class="mr-3">Cancellation Fee:</label>
            <input
              v-model="restaurant.cancellation_fee"
              type="text"
              class="form-control"
              placeholder="0"
            />
          </div>
          <div class="form-group d-flex align-items-center">
            <label class="mr-3">Cancellation Time (Minute):</label>
            <input
              v-model="restaurant.cancellation_time"
              type="text"
              class="form-control"
              placeholder="00:00:00"
            />
          </div>
          <div class="vatValue-wrapper">
            <div
              class="form-group d-flex align-items-center"
              style="width: 100%"
            >
              <label class="mr-3">Service Charges (%) :</label>
              <div class="vatValueInner">
                <input
                  @keyup="calculateServiceChargesValue"
                  v-model="restaurant.service_charge"
                  type="number"
                  min="1"
                  class="form-control firstVatInput"
                  placeholder="0.00"
                />
                <span>=</span>
                <div class="vatSecondInput">
                  <input
                    readonly
                    v-model="calculatedServiceCharges"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100">
          <h4 class="card-label border-bottom">
            Loyalty Points
          </h4>
          <div class="tabs-content-wrapper loyality-wrapper">
            <div class="tabs-inner-content">
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Has Loyalty:</label>
                <b-form-select
                  v-model="restaurant.has_loyalty"
                  :options="loyalty_options"
                  class=""
                  value-field="value"
                  text-field="text"
                ></b-form-select>
              </div>
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Spent Amount (£) :</label>
                <input
                  v-model="restaurant.spent_amount"
                  type="number"
                  min="1"
                  class="form-control"
                  placeholder="£5"
                />
              </div>
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Points Earned:</label>
                <input
                  v-model="restaurant.points_earned"
                  type="text"
                  class="form-control"
                  placeholder="45"
                />
              </div>
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Points:</label>
                <input
                  v-model="restaurant.points"
                  type="text"
                  class="form-control"
                  placeholder="30"
                />
              </div>
            </div>
            <div class="tabs-inner-content">
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Redeem Amount:</label>
                <input
                  v-model="restaurant.redeem_amount"
                  type="text"
                  class="form-control"
                  placeholder="$5"
                />
              </div>
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Welcome Points:</label>
                <input
                  v-model="restaurant.welcome_points"
                  type="text"
                  class="form-control"
                  placeholder="5"
                />
              </div>
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Threshold%:</label>
                <input
                  v-model="restaurant.threshold"
                  type="text"
                  class="form-control"
                  disabled="disabled"
                  placeholder="0.1"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-100">
          <h4 class="card-label border-bottom">
            Setting Tax Info
          </h4>
          <div class="tabs-content-wrapper loyality-wrapper">
            <div class="tabs-inner-content">
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Taxable:</label>
                <b-form-select
                  v-model="restaurant.taxable_selected"
                  :options="taxable_options"
                  class=""
                  value-field="item"
                  text-field="name"
                ></b-form-select>
              </div>
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Vat Number:</label>
                <input
                  v-model="restaurant.vat_number"
                  type="text"
                  class="form-control"
                  placeholder="45"
                />
              </div>
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Vat (%) :</label>
                <input
                  @keyup="calculateVatValue"
                  v-model="restaurant.vat_value"
                  type="number"
                  min="1"
                  max="100"
                  step="1"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  class="form-control"
                  placeholder="£5"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-100">
          <h4 class="card-label border-bottom">
            Tablet Orders Setting
          </h4>
          <div class="tabs-content-wrapper loyality-wrapper">
            <div class="tabs-inner-content">
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Order Appearance:</label>
                <b-form-select
                  v-model="restaurant.order_option"
                  :options="order_options"
                  class=""
                  value-field="value"
                  text-field="text"
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100">
          <h4 class="card-label border-bottom">
            Promotion
          </h4>
          <div class="tabs-content-wrapper loyality-wrapper">
            <div class="tabs-inner-content">
              <div class="form-group d-flex align-items-center">
                <label class="mr-3">Restaurant Promoted:</label>
                <b-form-select
                  v-model="restaurant.promoted_selected"
                  :options="promoted_options"
                  class=""
                  value-field="value"
                  text-field="text"
                ></b-form-select>
              </div>
            </div>
          </div>
          <div v-if="restaurant.promoted_selected">
            <div class="tabs-content-wrapper loyality-wrapper">
              <div class="tabs-inner-content">
                <div class="form-group d-flex align-items-center">
                  <label class="mr-3">Start Date</label><br />
                  <VueCtkDateTimePicker v-model="restaurant.start_date" />
                </div>
              </div>
              <div class="tabs-inner-content">
                <div class="form-group d-flex align-items-center">
                  <label class="mr-3">End Date</label><br />
                  <VueCtkDateTimePicker v-model="restaurant.end_date" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="working-days-title mt-4 mb-4">
        <h4 class="mb-0 card-label">Working Days</h4>
      </div>
      <div>
        <div class="color-box d-flex justify-content-center flex-wrap mb-4">
          <div class="switch-wrapper">
            <label class="switch flex-column">
              <input
                type="checkbox"
                value="true"
                @change="changeColor('Mon')"
                :checked="status.mon"
              />
              <span class="slider round"></span>
              Monday
            </label>
          </div>
          <div class="switch-wrapper">
            <label class="switch flex-column">
              <input
                type="checkbox"
                value="false"
                @change="changeColor('Tue')"
                :checked="status.tue"
              /><span class="slider round"></span>
              Tuesday
            </label>
          </div>
          <div class="switch-wrapper">
            <label class="switch flex-column">
              <input
                type="checkbox"
                value="false"
                @change="changeColor('Wed')"
                :checked="status.wed"
              /><span class="slider round"></span>
              Wednesday
            </label>
          </div>
          <div class="switch-wrapper">
            <label class="switch flex-column">
              <input
                type="checkbox"
                value="false"
                @change="changeColor('Thu')"
                :checked="status.thu"
              /><span class="slider round"></span>
              Thursday
            </label>
          </div>
          <div class="switch-wrapper">
            <label class="switch flex-column">
              <input
                type="checkbox"
                value="false"
                @change="changeColor('Fri')"
                :checked="status.fri"
              /><span class="slider round"></span>
              Friday
            </label>
          </div>
          <div class="switch-wrapper">
            <label class="switch flex-column">
              <input
                type="checkbox"
                value="false"
                @change="changeColor('Sat')"
                :checked="status.sat"
              /><span class="slider round"></span>
              Saturday
            </label>
          </div>
          <div class="switch-wrapper">
            <label class="switch flex-column">
              <input
                type="checkbox"
                value="false"
                @change="changeColor('Sun')"
                :checked="status.sun"
              /><span class="slider round"></span>
              Sunday
            </label>
          </div>
        </div>
      </div>
      <div class="working-days-title mt-4 mb-4">
        <h4 class="mb-0 card-label">Opening Timings</h4>
      </div>
      <div>
        <div
          class="d-flex justify-content-between align-items-center newWorkingDays-wrapper"
        >
          <button
            :disabled="workingDaysLength > 1"
            type="button"
            class="btn custom-btn"
            @click="selectAllDays"
          >
            {{ selectText }}
          </button>
          <button
            v-if="permission.is_add == 1"
            :disabled="selectAll"
            type="button"
            class="btn add-btn"
            @click="addWorkingDays"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
        <working-days-new
          v-for="(days, index) in workingDays"
          @update-busy-day="updateBusyDays"
          @delete-working-day="removeWorkingDays"
          track-by="index"
          :busyDays="busyDays"
          :length="workingDays"
          :days="days"
          :key="index"
          :index="index"
          :isSelectAll="selectAll"
        ></working-days-new>
      </div>
      <div class="text-center">
        <button
          v-if="permission.is_edit == 1"
          type="button"
          @click="addRestaurantSettings"
          class="btn custom-btn-style mt-5"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</template>

<style scoped></style>
<script>
import WorkingDaysNew from "./WorkingDaysNew";
import ApiService from "@/core/services/api.service";
import moment from "moment";
import helpers from "../../helpers";
import axios from "axios";

export default {
  name: "RestaurantSettings",
  data() {
    return {
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      workingDays: [
        {
          opening_1: "",
          opening_2: "",
          days: []
        }
      ],
      busyDays: [],
      selectAll: false,
      monActive: true,
      tueActive: true,
      wedActive: true,
      thuActive: true,
      friActive: true,
      satActive: true,
      sunActive: true,
      status: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false
      },
      is_monday: 0,
      is_tuesday: 0,
      is_wednesday: 0,
      is_thursday: 0,
      is_friday: 0,
      is_saturday: 0,
      is_sunday: 0,
      loader: false,
      restaurantUuid: this.$route.params.restaurant_uuid,
      halal_options: [
        { item: 1, name: "Yes" },
        { item: 0, name: "No" }
      ],
      promoted_options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ],
      loyalty_options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ],
      order_options: [
        {
          text: "Hide the order after clicking the Smart Call button",
          value: 0
        },
        { text: "Hide the order after clicking the Ready button", value: 1 }
      ],
      currency_options: [
        { item: 1, name: "£ Pound Sterling" },
        { item: 2, name: "$ Us Dollar" },
        { item: 3, name: "€ Euro" }
      ],
      delivery_options: [
        { item: 1, name: "Yes" },
        { item: 0, name: "No" }
      ],
      taxable_options: [
        { item: 1, name: "Yes" },
        { item: 0, name: "No" }
      ],
      restaurant: {
        halal_selected: "",
        promoted_selected: "",
        delivery_selected: "",
        order_option: "",
        delivery_within: "",
        delivery_fee: "",
        minimum_order: "",
        delivery_starting: "",
        delivery_time: "",
        collection_time: "",
        cancellation_fee: "",
        cancellation_time: "",
        service_charge: "",
        spent_amount: "",
        points_earned: "",
        points: "",
        redeem_amount: "",
        welcome_points: "",
        threshold: 0.1,
        taxable_selected: "",
        vat_number: "",
        vat_value: "",
        start_date: "",
        end_date: "",
        has_loyalty: ""
      },
      calculatedVat: 0,
      calculatedServiceCharges: 0,
      selectText: "Select All",
      workingDaysLength: 0
    };
  },
  components: {
    WorkingDaysNew
  },
  computed: {},
  methods: {
    format24To12(time) {
      var hrs = 0;
      var min = 0;
      var timeHours = 0;
      var hours = 0;
      var tempAry = time.split(":");
      hrs = parseInt(tempAry[0], 10);
      min = parseInt(tempAry[1], 10);
      if (min == 0) {
        min = "00";
      }
      if (hrs >= 12) {
        hours = hrs - 12;
        if (hours == 12) {
          timeHours = "00:" + min + "AM";
        } else {
          timeHours = "0" + hours + ":" + min + "PM";
        }
      } else {
        timeHours = hrs + ":" + min + "AM";
      }
      return timeHours;
    },
    getTime(time) {
      var datetime = time;
      var d1 = new Date(datetime);
      var minute = d1.getMinutes();
      // var minute = d1.getUTCMinutes();
      var hour = d1.getHours();
      // var hour = d1.getUTCHours();
      if (minute == 0) return this.format24To12(hour + ":00");
      else return this.format24To12(hour + ":" + minute);
    },
    getDate(dateTime) {
      var datetime = dateTime;
      var date = datetime.split(" ");
      return date[0];
    },
    selectAllDays() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.selectText = "Unselect All";
      } else {
        this.selectText = "Select All";
      }
    },
    addWorkingDays() {
      this.updateBusyDays();
      this.workingDays.push({
        opening_1: "",
        opening_2: "",
        days: []
      });
      this.workingDaysLength = this.workingDays.length;
    },
    removeWorkingDays(index) {
      this.workingDays.splice(parseInt(index), 1);
      this.workingDaysLength = this.workingDays.length;
    },
    updateBusyDays() {
      this.workingDays.forEach(x => {
        this.busyDays = this.busyDays.concat(x.days);
      });
    },
    changeColor(day) {
      if (day === "Mon") {
        if (this.is_monday === 1) {
          this.is_monday = 0;
        } else {
          this.is_monday = 1;
        }
        if (this.status.mon) {
          this.monActive = !this.monActive;
          this.status.mon = !this.status.mon;
        } else {
          this.monActive = !this.monActive;
          this.status.mon = !this.status.mon;
        }
      }
      if (day === "Tue") {
        if (this.is_tuesday == 1) {
          this.is_tuesday = 0;
        } else {
          this.is_tuesday = 1;
        }
        if (this.status.tue) {
          this.tueActive = !this.tueActive;
          this.status.tue = !this.status.tue;
        } else {
          this.tueActive = !this.tueActive;
          this.status.tue = !this.status.tue;
        }
      }
      if (day === "Wed") {
        if (this.is_wednesday == 1) {
          this.is_wednesday = 0;
        } else {
          this.is_wednesday = 1;
        }
        if (this.status.wed) {
          this.wedActive = !this.wedActive;
          this.status.wed = !this.status.wed;
        } else {
          this.wedActive = !this.wedActive;
          this.status.wed = !this.status.wed;
        }
      }
      if (day === "Thu") {
        if (this.is_thursday == 1) {
          this.is_thursday = 0;
        } else {
          this.is_thursday = 1;
        }
        if (this.status.thu) {
          this.thuActive = !this.thuActive;
          this.status.thu = !this.status.thu;
        } else {
          this.thuActive = !this.thuActive;
          this.status.thu = !this.status.thu;
        }
      }
      if (day === "Fri") {
        if (this.is_friday == 1) {
          this.is_friday = 0;
        } else {
          this.is_friday = 1;
        }
        if (this.status.fri) {
          this.friActive = !this.friActive;
          this.status.fri = !this.status.fri;
        } else {
          this.friActive = !this.friActive;
          this.status.fri = !this.status.fri;
        }
      }
      if (day === "Sat") {
        if (this.is_saturday == 1) {
          this.is_saturday = 0;
        } else {
          this.is_saturday = 1;
        }
        if (this.status.sat) {
          this.satActive = !this.satActive;
          this.status.sat = !this.status.sat;
        } else {
          this.satActive = !this.satActive;
          this.status.sat = !this.status.sat;
        }
      }
      if (day === "Sun") {
        if (this.is_sunday == 1) {
          this.is_sunday = 0;
        } else {
          this.is_sunday = 1;
        }
        if (this.status.sun) {
          this.sunActive = !this.sunActive;
          this.status.sun = !this.status.sun;
        } else {
          this.sunActive = !this.sunActive;
          this.status.sun = !this.status.sun;
        }
      }
    },
    calculateVatValue() {
      this.calculatedVat = this.restaurant.vat_value / 100;
    },
    calculateServiceChargesValue() {
      this.calculatedServiceCharges = this.restaurant.service_charge / 100;
    },
    getRestaurantDetails() {
      axios
        .get(this.$path + "/admin/restaurant/" + this.restaurantUuid)
        .then(response => {
          this.getRestaurantProfile(response.data.data.restaurant_profile);
          this.getRestaurantDays(response.data.data.working_hours);
          this.getPromotion(response.data.data.promotion);
          this.restaurant.promoted_selected =
            response.data.data.promotion_enabled;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    addRestaurantSettings() {
      this.loader = true;
      let obj = {
        is_halal: this.restaurant.halal_selected,
        has_delivery: this.restaurant.delivery_selected,
        delivery_range: this.restaurant.delivery_within,
        delivery_charges: this.restaurant.delivery_fee,
        min_order_amount: this.restaurant.minimum_order,
        delivery_start_at: this.deliveryStartingTimeFormat(
          this.restaurant.delivery_starting
        ),
        delivery_time: this.restaurant.delivery_time,
        collection_time: this.restaurant.collection_time,
        order_cancel_charge: this.restaurant.cancellation_fee,
        order_cancel_withen: this.restaurant.cancellation_time,
        service_charges: this.calculatedServiceCharges,
        has_loyalty: this.restaurant.has_loyalty,
        amount_earning_loyalty: this.restaurant.spent_amount,
        loyalty_points_earning: this.restaurant.points_earned,
        loyalty_points_redeem: this.restaurant.points,
        redeem_points_amount: this.restaurant.redeem_amount,
        loyalty_welcome_points: this.restaurant.welcome_points,
        is_taxable: this.restaurant.taxable_selected,
        vat_number: this.restaurant.vat_number,
        vat_value: this.calculatedVat,
        is_promoted: this.restaurant.promoted_selected,
        hide_order_after_ready: this.restaurant.order_option,
        start_date: this.restaurant.start_date,
        end_date: this.restaurant.end_date,
        is_monday: this.is_monday,
        is_tuesday: this.is_tuesday,
        is_wednesday: this.is_wednesday,
        is_thursday: this.is_thursday,
        is_friday: this.is_friday,
        is_saturday: this.is_saturday,
        is_sunday: this.is_sunday,
        is_select: this.selectAll,
        workingDays: this.workingDays
      };

      ApiService.patch(
        this.$path + "/admin/restaurant/" + this.restaurantUuid,
        obj
      )
        .then(response => {
          helpers.successMessages(this, response.data.message);
          this.getRestaurantProfile(response.data.data.restaurant_profile);
          this.getRestaurantDays(response.data.data.working_hours);
          this.getPromotion(response.data.data.promotion);
          this.restaurant.promoted_selected =
            response.data.data.promotion_enabled;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    deliveryStartingTimeFormat(time) {
      if (time.length === 5) {
        return time + ":00";
      }
      return time;
    },
    getRestaurantProfile(restaurantProfile) {
      if (restaurantProfile) {
        this.restaurant.delivery_selected = restaurantProfile.has_delivery;
        this.restaurant.halal_selected = restaurantProfile.is_halal;
        this.restaurant.delivery_within = restaurantProfile.delivery_range;
        this.restaurant.delivery_fee = restaurantProfile.delivery_charges;
        this.restaurant.minimum_order = restaurantProfile.min_order_amount;
        this.restaurant.delivery_starting = restaurantProfile.delivery_start_at;
        this.restaurant.delivery_time = restaurantProfile.delivery_time;
        this.restaurant.collection_time = restaurantProfile.collection_time;
        this.restaurant.cancellation_fee =
          restaurantProfile.order_cancel_charge;
        this.restaurant.cancellation_time =
          restaurantProfile.order_cancel_withen;
        this.calculatedServiceCharges = restaurantProfile.service_charges;
        this.restaurant.service_charge =
          restaurantProfile.service_charges * 100;
        this.restaurant.spent_amount = restaurantProfile.amount_earning_loyalty;
        this.restaurant.points_earned =
          restaurantProfile.loyalty_points_earning;
        this.restaurant.points = restaurantProfile.loyalty_points_redeem;
        this.restaurant.redeem_amount = restaurantProfile.redeem_points_amount;
        this.restaurant.welcome_points =
          restaurantProfile.loyalty_welcome_points;
        this.restaurant.taxable_selected = restaurantProfile.is_taxable;
        this.restaurant.vat_number = restaurantProfile.vat_number;
        this.restaurant.vat_value = restaurantProfile.vat_value * 100;
        this.restaurant.order_option = restaurantProfile.hide_order_after_ready;
        this.restaurant.has_loyalty = restaurantProfile.has_loyalty;
      }
    },
    getRestaurantDays(workingHours) {
      if (workingHours) {
        if (workingHours.opening_hours) {
          this.workingDays = workingHours.opening_hours;
          this.workingDaysLength = workingHours.opening_hours.length;
        }
        this.is_monday = workingHours.is_monday;
        this.is_tuesday = workingHours.is_tuesday;
        this.is_wednesday = workingHours.is_wednesday;
        this.is_thursday = workingHours.is_thursday;
        this.is_friday = workingHours.is_friday;
        this.is_saturday = workingHours.is_saturday;
        this.is_sunday = workingHours.is_sunday;
        if (this.is_monday === 0) {
          this.status.mon = false;
          this.monActive = true;
        } else {
          this.status.mon = true;
          this.monActive = false;
        }
        if (this.is_tuesday === 0) {
          this.status.tue = false;
          this.tueActive = true;
        } else {
          this.status.tue = true;
          this.tueActive = false;
        }
        if (this.is_wednesday === 0) {
          this.status.wed = false;
          this.wedActive = true;
        } else {
          this.status.wed = true;
          this.wedActive = false;
        }
        if (this.is_thursday === 0) {
          this.status.thu = false;
          this.thuActive = true;
        } else {
          this.status.thu = true;
          this.thuActive = false;
        }
        if (this.is_friday === 0) {
          this.status.fri = false;
          this.friActive = true;
        } else {
          this.status.fri = true;
          this.friActive = false;
        }
        if (this.is_saturday === 0) {
          this.status.sat = false;
          this.satActive = true;
        } else {
          this.status.sat = true;
          this.satActive = false;
        }
        if (this.is_sunday === 0) {
          this.status.sun = false;
          this.sunActive = true;
        } else {
          this.status.sun = true;
          this.sunActive = false;
        }
      }
    },
    getPromotion(promotion) {
      if (promotion) {
        this.restaurant.start_date = moment(promotion.start_at).format(
          "YYYY-MM-DD hh:mm a"
        );
        this.restaurant.end_date = moment(promotion.end_at).format(
          "YYYY-MM-DD hh:mm a"
        );
      }
    }
  },
  mounted() {
    this.addWorkingDays();
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantUuid = this.restaurantData.uuid;
    this.getRestaurantDetails();
    this.calculatedVat = this.restaurant.vat_value / 100;
    let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    this.permission = {
      is_add: permission.is_add,
      is_deleted: permission.is_deleted,
      is_edit: permission.is_edit,
      web_access_enabled: permission.web_access_enabled
    };
  }
};
</script>
