const helpers = {
  errorOther(vm, error) {
    vm.$bvToast.toast(error.message, {
      title: "Error",
      variant: "danger",
      solid: true,
      toaster: "b-toaster-top-center"
    });
  },
  errorMessage(vm, error) {
    vm.$bvToast.toast(error, {
      title: "Error",
      variant: "danger",
      solid: true,
      toaster: "b-toaster-top-center"
    });
  },
  validationError(vm, error) {
    vm.$bvToast.toast(error[Object.keys(error)[0]][0], {
      title: "Error",
      variant: "danger",
      solid: true,
      toaster: "b-toaster-top-center"
    });
  },
  serverError(vm) {
    vm.$bvToast.toast("Server Error", {
      title: "Error",
      variant: "danger",
      solid: true,
      toaster: "b-toaster-top-center"
    });
  },
  successMessages(vm, message) {
    vm.$bvToast.toast(message, {
      title: "Success",
      variant: "success",
      solid: true,
      toaster: "b-toaster-top-center"
    });
  }
};
export default helpers;
