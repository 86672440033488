<template>
  <div class="newWorkingDays-wrapper">
    <div class="row">
      <div class="col-sm-12 mb-4"></div>
      <div class="col-sm-12">
        <div class="newWorkingDays-inner row">
          <button
            v-if="length.length > 1"
            type="button"
            class="trash-row"
            @click="deleteRow"
          >
            <i class="fas fa-trash-alt d-inline-block"></i>
          </button>
          <div
            class="newWorkingDays-time col-md-5 col-lg-4 col-xl-4 mb-3 mb-lg-0 mb-xl-0 mb-md-0"
          >
            <div class="form-group">
              <label class="text-left">Opening Time 1</label>
              <VueTimepicker
                format="HH:mm:ss"
                v-model="days.opening_1"
              ></VueTimepicker>
            </div>
            <div class="form-group">
              <label class="text-left">Opening Time 2</label>
              <VueTimepicker
                format="HH:mm:ss"
                v-model="days.opening_2"
              ></VueTimepicker>
            </div>
          </div>
          <div
            class="newWorking-daysName col-md-7 col-lg-8 col-xl-8 mb-3 mb-lg-0 mb-xl-0 ml-auto"
          >
            <div class="checkbox-buttons-container">
              <input
                type="checkbox"
                class="helo"
                :checked="checked"
                :id="'CheckboxButton1' + index"
                v-model="days.days"
                value="1"
              />
              <label :for="'CheckboxButton1' + index">Monday</label>
              <input
                class="helo"
                type="checkbox"
                :checked="checked"
                :id="'CheckboxButton2' + index"
                v-model="days.days"
                value="2"
              />
              <label :for="'CheckboxButton2' + index">Tuesday</label>
              <input
                class="helo"
                type="checkbox"
                :checked="checked"
                :id="'CheckboxButton3' + index"
                v-model="days.days"
                value="3"
              />
              <label :for="'CheckboxButton3' + index">Wednesday</label>
              <input
                class="helo"
                type="checkbox"
                :checked="checked"
                :id="'CheckboxButton4' + index"
                v-model="days.days"
                value="4"
              />
              <label :for="'CheckboxButton4' + index">Thursday</label>
              <input
                class="helo"
                type="checkbox"
                :checked="checked"
                :id="'CheckboxButton5' + index"
                v-model="days.days"
                value="5"
              />
              <label :for="'CheckboxButton5' + index">Friday</label>
              <input
                class="helo"
                type="checkbox"
                :checked="checked"
                :id="'CheckboxButton6' + index"
                v-model="days.days"
                value="6"
              />
              <label :for="'CheckboxButton6' + index">Saturday</label>
              <input
                class="helo"
                type="checkbox"
                :checked="checked"
                :id="'CheckboxButton7' + index"
                v-model="days.days"
                value="7"
              />
              <label :for="'CheckboxButton7' + index">Sunday</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  name: "WorkingDaysNew",
  props: ["days", "isSelectAll", "index", "busyDays", "length"],
  components: {
    VueTimepicker
  },
  data() {
    return {
      checked: false
    };
  },
  methods: {
    deleteRow() {
      this.$emit("delete-working-day", this.index);
    },
    isValidDays(day) {
      return this.busyDays.includes(day);
    }
  },
  mounted() {},
  watch: {
    "days.days": {
      handler: function() {
        this.$emit("update-busy-day", "true");
      },
      deep: true
    },
    isSelectAll() {
      if (this.isSelectAll) {
        $("input.helo[type=checkbox]:checkbox").each(function() {
          this.checked = true;
        });
      } else {
        $("input.helo[type=checkbox]:checkbox").each(function() {
          this.checked = false;
        });
      }
    }
  }
};
</script>

<style scoped>
.vue__time-picker {
  width: 100%;
}
</style>
